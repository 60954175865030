@media (min-width:1000px) {
    .option1 {
        position: absolute;
        bottom: 10vw;
        left: 0vw;
        width: 20vw;
        height: 6vw;
    }

    .option2 {
        position: absolute;
        bottom: 2vw;
        left: 20vw;
        width: 21vw;
        height: 6vw;
    }

    .option3 {
        position: absolute;
        bottom: 5vw;
        left: 42vw;
        width: 20vw;
        height: 6vw;
    }

    .option4 {
        position: absolute;
        bottom: 5vw;

        left: 60vw;
        width: 20vw;
        height: 6vw;
    }

    .option5 {
        position: absolute;
        bottom: 10vw;
        right: 5vw;
        width: 15vw;
        height: 6vw;
    }

}

@media (max-width:1000px) {
    .option1 {
        position: absolute;
        bottom: 40vw;
        left: 0vw;
        width: 30vw;
        height: 6vw;
    }

    .option4 {
        position: absolute;
        bottom: 20vw;
        left: 10vw;
        width: 21vw;
        height: 6vw;
    }

    .option2 {
        position: absolute;
        bottom: 40vw;
        left: 30vw;
        width: 20vw;
        height: 6vw;
    }

    .option3 {
        position: absolute;
        right: 15vw;

        bottom: 40vw;
        width: 20vw;
        height: 6vw;
    }

    .option5 {
        position: absolute;
        bottom: 20vw;
        right: 20vw;
        width: 25vw;
        height: 6vw;
    }

}