.team1 {
    width: 25vw;
    height: 8vw;
    background-image: url(../assets/teams/team1.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 2000;
}

.team2 {
    cursor: pointer;
    z-index: 2000;
    
    width: 25vw;
    height: 8vw;
    background-image: url(../assets/teams/team2.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
}

.team3 {
    z-index: 3000;
    width: 25vw;
    height: 8vw;
    background-image: url(../assets/teams/team3.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

}

.team4 {
    margin-top: 15vw;
    
    cursor: pointer;
    width: 25vw;
    height: 8vw;
    background-image: url(../assets/teams/team4.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
}

.team5 {
    margin-top: 15vw;
    
    cursor: pointer;
    width: 25vw;
    height: 8vw;
    background-image: url(../assets/teams/team5.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
}

.selectTeamContainer {
    width: 100vw;
    height: 100%;
    background-image: url(../assets/selectTeamBg.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

.rowContainer {
    top: 40%;
    position: absolute;
    width: 100vw;
    display: flex;
    /* height: 100%; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}