.App {
  text-align: center;
  /* background-image: url(./assets/bg.png); */
  width: 100vw;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.firstText {
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-left: 1.5vw;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  font-size: 1.2vw;
  width: 25vw;
}

.secondText {
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-left: 1.5vw;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  font-size: 1.2vw;

  width: 25vw;

}

.thirdText {

  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-left: 1.5vw;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  font-size: 1.2vw;
  width: 25vw;

}

.fourthText {
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-left: 1.5vw;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  font-size: 1.2vw;

  width: 25vw;

}

.fifthText {
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-left: 1.5vw;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  font-size: 1.2vw;

  width: 25vw;

}

.img1 {
  position: absolute;
  top: -1vw;
  left: -9vw;
  background-image: url(./assets/1.png);
  width: 10vw;
  height: 8vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3000;
}

.img2 {
  position: absolute;
  top: -1vw;
  left: -9vw;

  background-image: url(./assets/2.png);
  width: 10vw;

  height: 8vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3000;
}

.img3 {
  position: absolute;
  top: -1vw;
  left: -9vw;

  background-image: url(./assets/3.png);
  width: 10vw;

  height: 8vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3000;
}


.img4 {
  position: absolute;
  top: -1vw;
  left: -9vw;

  background-image: url(./assets/4.png);
  width: 10vw;

  height: 8vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3000;
}

.img5 {
  position: absolute;
  top: -1vw;
  left: -14vw;
  background-image: url(./assets/5.png);
  width: 10vw;

  height: 8vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3000;
}

.optionsText {
  border-width: 0px;
  border-style: solid;
  font-size: 1vw;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.num1 {
  position: relative;
  margin-right: 10px;
  background-image: url(./assets/numbers/1.png);
  width: 4vw;
  height: 4vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3000;
}

.num2 {
  position: relative;
  background-image: url(./assets/numbers/2.png);
  width: 20px;
  width: 4vw;
  height: 4vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3000;
  margin-right: 10px;

}

.num3 {
  margin-right: 10px;

  position: relative;
  background-image: url(./assets/numbers/3.png);
  width: 4vw;
  height: 4vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3000;
}

.num4 {
  margin-right: 10px;

  position: relative;
  background-image: url(./assets/numbers/4.png);
  width: 4vw;
  height: 4vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3000;
}

.num5 {
  margin-right: 10px;
  position: relative;
  background-image: url(./assets/numbers/5.png);
  width: 4vw;
  height: 4vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3000;
}

.first {
  border-radius: 40px;
  padding-left: 5vw;
  text-align: start;
  top: 13vw;
  left: 20vw;
  position: absolute;
  display: flex;
  width: 20vw;
  height: 30px;
}

.second {
  text-align: start;
  border-radius: 40px;
  top: 13vw;
  right: 15vw;
  position: absolute;
  display: flex;
  width: 20vw;
  height: 30px;
}

.third {
  text-align: start;
  border-radius: 40px;

  top: 22vw;
  left: 20vw;
  position: absolute;
  display: flex;
  width: 20vw;
  height: 30px;
}

.fourth {
  border-radius: 40px;
  font-size: 10vw;
  text-align: start;
  top: 22vw;

  right: 15vw;
  position: absolute;
  display: flex;
  width: 20vw;
  height: 30px;
}

.fifth {
  border-radius: 40px;
  font-size: 10vw;
  text-align: start;
  top: 30vw;
  left: 40vw;
  position: absolute;
  display: flex;
  width: 20vw;
  height: 30px;
}

@media (max-width:1025px) {
  .first {
    top: 22vw;

  }

  .second {
    top: 22vw;

  }

  .third {
    top: 32vw;

  }

  .fourth {
    top: 32vw;

  }

  .fifth {
    top:42vw;

  }
}

@media (min-width:2000px) {
  .first {
    top: 15vw;
  }

  .second {
    top: 15vw;
  }

  .third {
    top: 30vw;
  }

  .fourth {
    top: 30vw;
  }
}

@media (max-width:500px) {
  .options {
    flex-direction: column;
    align-items: center;
    bottom: 3vw !important;
  }
}

/* /ipad portrait  */
@media screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  .options {
    flex-direction: column;
    align-items: center;
    bottom: 3vw !important;
  }

  .optionsText {
    /* width: 50vw; */
    /* margin: 5px; */
    font-size: 1.7vw;
    width: 26vw;
  }

  .first {
    top: 40vw;
    left: 15vw;
  }

  .second {
    top: 40vw;
    right: 15vw;
  }

  .third {
    top: 52vw;
    left: 15vw;
  }

  .fourth {
    top: 52vw;
    right: 15vw;
  }
  .fifth {
    top: 65vw;
    right: 15vw;
  }
}

@media (min-width:1629px) and (max-width:2000px) {

  .first {
    top: 12vw;
    left: 15vw;
  }

  .second {
    top: 12vw;
    right: 15vw;
  }

  .third {
    top: 25vw;
    left: 15vw;
  }

  .fourth {
    top: 25vw;
    right: 15vw;
  }
}

@font-face {
  font-family: luckiestGuy;
  src: url(./assets/fonts/luckiestguy.ttf);
}

@media (max-width:1200px) and (min-width:1100px) {

  .first {
    z-index: 3000;
    top: 22vw;
    left: 15vw;
  }

  .second {
    top: 22vw;
    right: 15vw;
  }

  .third {
    top: 35vw;
    left: 15vw;
  }

  .fourth {
    top: 35vw;
    right: 15vw;
  }

  .fifth {
    top: 45vw;
    right: 15vw;
  }
}


.dndContainer {
  width: 100vw;
  height: 100%;
  background-image: url(./assets/bg.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.draggables {

  bottom: 40vw;
  width: 100%;
  height: 100vh;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.options {
  align-self: center;
  /* columns: 2 30vw; */
  text-align: start;
  display: flex;
  /* flex-direction: row; */
  position: absolute;
  left: 2vw;
  bottom: 1vw;
  justify-content: center;
  width: 100vw
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.drag-n-drop {
  padding: .5rem;
  display: grid;
  gap: .5rem;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(auto-fill, 300px);
  align-items: start;
}

.dnd-group {
  background-color: #49505e;
  border-radius: 5px;
  padding: .5rem;
}

.dnd-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  background-color: white;
  border-radius: 5px;
  color: #282c34;
  font-weight: bold;
}

.dnd-item:not(:last-of-type) {
  margin-bottom: .5rem;
}

.dnd-item * {
  margin: 0;
  font-size: 1.2rem;
}

.group-title {
  text-align: left;
  margin-bottom: .5rem;
  font-size: 1.5rem;
}

.current {
  background-color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}